import React, {Component} from 'react';

import {connect} from 'react-redux';
import {compose} from 'redux';
import {withRouter} from 'react-router-dom';

import {injectIntl, intlShape, FormattedMessage} from '../../util/reactIntl';

import SearchPageWithList from './SearchPageWithList';
import SearchPageWithMap from './SearchPageWithMap';

import {getListingsById} from '../../ducks/marketplaceData.duck';
import {manageDisableScrolling, isScrollingDisabled} from '../../ducks/UI.duck';
export class SearchPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      view: 'list',
    }

    this.handleView = this.handleView.bind(this)
  }

  handleView(view) {
    //something here
    this.setState({view: view})
  }

  render() {
    return (
      <>
        {this.state.view === 'list' && <SearchPageWithList {...{handleView: this.handleView, ...this.props}} />}
        {this.state.view === 'map' && <SearchPageWithMap {...{handleView: this.handleView, ...this.props}} />}
      </>
    )
  }
}

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
  } = state.SearchPage;
  const pageListings = getListingsById(state, currentPageResultIds);

  return {
    listings: pageListings,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    searchParams,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

const SearchPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(SearchPageComponent);

export default SearchPage;
