import React from 'react';
import { bool } from 'prop-types';
import SelectSingleFilterPlainNested from './SelectSingleFilterPlainNested';
import SelectSingleFilterPopupNested from './SelectSingleFilterPopupNested';

const SelectSingleFilterNested = props => {
  const { showAsPopup, ...rest } = props;
  return showAsPopup ? (
    <SelectSingleFilterPopupNested {...rest} />
  ) : (
    <SelectSingleFilterPlainNested {...rest} />
  );
};

SelectSingleFilterNested.defaultProps = {
  showAsPopup: false,
};

SelectSingleFilterNested.propTypes = {
  showAsPopup: bool,
};

export default SelectSingleFilterNested;
