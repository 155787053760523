import React, {Component} from 'react';
import {arrayOf, bool, func, node, object, shape, string} from 'prop-types';
import classNames from 'classnames';

import {FormattedMessage} from '../../../util/reactIntl';

import IconPlus from '../IconPlus/IconPlus';

import css from './SelectSingleFilterPlainNested.module.css';

const getQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
};

class SelectSingleFilterPlainNested extends Component {
  constructor(props) {
    super(props);

    let isOpen = false
    const optionKeys = props.options.map(option => option.key)

    const queryParamName = getQueryParamName(props.queryParamNames);

    const initialValue = props?.initialValues?.[queryParamName]

    initialValue && initialValue.split(',').forEach(value => {
      if (optionKeys.includes(value)) {
        isOpen = true
      }
    })
    this.state = {isOpen: isOpen};
    this.selectOption = this.selectOption.bind(this);
    this.toggleIsOpen = this.toggleIsOpen.bind(this);
  }

  selectOption(option, e) {
    const {queryParamNames, onSelect} = this.props;
    const queryParamName = getQueryParamName(queryParamNames);
    onSelect({[queryParamName]: option});

    if (!option) {
      this.setState({isOpen: !this.state.isOpen});
    }
    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  }

  toggleIsOpen(option, e) {
    this.setState({isOpen: !this.state.isOpen});
    const {queryParamNames, onSelect} = this.props;
    const queryParamName = getQueryParamName(queryParamNames);

    onSelect({[queryParamName]: option});

    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  }

  render() {
    const {
      rootClassName,
      className,
      label,
      options,
      default_key,
      queryParamNames,
      initialValues,
      twoColumns,
      useBorder,
      useHighlight,
    } = this.props;

    const queryParamName = getQueryParamName(queryParamNames);
    const initialValue =
      initialValues && initialValues[queryParamName] ? initialValues[queryParamName] : null;
    const labelClass = initialValue ? css.labelSelected : css.label;

    const optionsContainerClass = classNames({
      [css.optionsContainerOpen]: this.state.isOpen,
      [css.optionsContainerClosed]: !this.state.isOpen,
      [css.twoColumns]: twoColumns,
    });

    const classes = classNames(rootClassName || css.root, className);

    return (
      <div className={classes}>
        <div className={css.filterHeader}>
          <button className={css.labelButton} key={default_key} onClick={() => this.toggleIsOpen(default_key)}>
            <span className={css.labelButtonContent}>
              <span className={labelClass}>{label}</span>
            </span>
          </button>
        </div>
        <div className={optionsContainerClass}>
          {options.map(option => {
            // check if this option is selected
            const selected = initialValue === option.key || (initialValue && initialValue.split(',').includes(option.key))

            const optionClass = classNames(css.option, {
              [css.optionHighlight]: selected && useHighlight,
            });
            // menu item selected bullet or border class
            const optionBorderClass = useBorder
              ? classNames({
                [css.optionBorderSelected]: selected,
                [css.optionBorder]: !selected,
              })
              : null;
            return (
              <button
                key={option.key}
                className={optionClass}
                onClick={() => this.selectOption(option.key)}
              >
                {useBorder ? <span className={optionBorderClass} /> : null}
                {option.label}
              </button>
            );
          })}
          <button className={css.clearButton} onClick={e => this.selectOption(null, e)}>
            <FormattedMessage id={'SelectSingleFilter.plainClear'} />
          </button>
        </div>
      </div>
    );
  }
}

SelectSingleFilterPlainNested.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  twoColumns: false,
  useHighlight: true,
  useBorder: false,
};

SelectSingleFilterPlainNested.propTypes = {
  rootClassName: string,
  className: string,
  queryParamNames: arrayOf(string).isRequired,
  label: node.isRequired,
  onSelect: func.isRequired,
  default_key: string.isRequired,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
  initialValues: object,
  twoColumns: bool,
  useHighlight: bool,
  useBorder: bool,
};

export default SelectSingleFilterPlainNested;
